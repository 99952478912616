import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { ClienteUrlApi } from "../cliente-url-api.model";
import * as PermissaoActions from './permissao.actions';

export interface PermissaoState {
  permissoes: ClienteUrlApi;
  error: string;
}

const initialState: PermissaoState = {
  permissoes: {
    CodCliente: 0,
    CodClienteUrlApi: 0,
    DscNomeCliente: '',
    DscUrlApi: '',
    DscUrlApiFastcidadao: '',
    IndUsoUpa: 0,
    IndFastCidadao: 0,
    IndFaq: 0,
    IndMedicamentos: 0,
    IndUnidadesBasicas: 0,
    IndOuvidoria: 0,
    IndMinhaSaude: 0,
    IndVacinas: 0,
    IndCovid: 0,
    IndPvCoracao: 0,
    IndIjf2: 0,
    IndHomePersonalizado: 0,
    IndVacinaCovid: 0,
    IndAceiteVacinaCovid: 0,
    IndConecteSus: 0,
    IndFaleComSara: 0,
    IndNucleoImagem: 0,
    IndServUteis: 0,
    IndDependentes: 0,
    IndFastConsultas: 0,
    IndPermissaoDependente: 0,
    IndDocAssinado: 0,
    IndTeleatendimento: 0,
    IndTeleatendimentoCompartilhado: 0,
    IndFastEsf: 0,
    IndAgendamentos: 0,
    IndNoticias: 0,
    IndAvisos: 0,
    IndCirurgias: 0,
    IndCns: 0,
    DscVersaoFastCidadao: '',
  },
  error: ''
};

//Selectors
const getPermissaoFeatureState = createFeatureSelector<PermissaoState>('permissao');

export const getPermissoesCliente = createSelector(
  getPermissaoFeatureState,
  state => state.permissoes,
);

export const getError = createSelector(
  getPermissaoFeatureState,
  state => state.error
);

//Reducer
export const permissaoReducer = createReducer<PermissaoState>(
  initialState,
  on(PermissaoActions.sucessoCarregarPermissoesCliente, (state, action): PermissaoState => {
    return {
      ...state,
      permissoes: action.permissoes,
      error: ''
    };
  }),
  on(PermissaoActions.erroCarregarPermissoesCliente, (state, action): PermissaoState => {
    return {
      ...state,
      permissoes: initialState.permissoes,
      error: action.error
    };
  })
);

