import { Component, OnDestroy, OnInit } from '@angular/core';
import { faHouse, faDisplayMedical, faCalendarDay, faBellPlus, faFile, faLaptopMedical, faSyringe, faHeartPulse, faNewspaper, faHospital, faSquareInfo, faHeadset, faMessagesQuestion, faCapsules, faAddressCard, faMonitorWaveform } from '@fortawesome/pro-light-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PermissaoState, getPermissoesCliente } from 'src/app/seguranca/cliente/state/permissao.reducer';
import { Helper } from 'src/app/shared/helper';
import { MenuLateralHref, MenuLateralRouterLink } from './menu-lateral.model';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss'],
})
export class MenuLateralComponent implements OnDestroy {
  title: string = 'PortalCidadao';
  menuRouterLink: MenuLateralRouterLink[] = [];
  menuHref: MenuLateralHref[] = [];
  permissoesSubscription: Subscription;

  constructor(
    private permissaoStore: Store<PermissaoState>,
    private helper: Helper
  ) {
    let context = this;
    this.permissoesSubscription = this.permissaoStore.select(getPermissoesCliente).subscribe(permissoes => {
      context.menuRouterLink = [
        {
          rota: '',
          pagina: 'Home',
          icone: faHouse,
          display: true,
        },
        {
          rota: 'teleatendimentos',
          pagina: 'Teleatendimento',
          icone: faLaptopMedical,
          display: this.helper.toBool(permissoes.IndTeleatendimento),
        },
        {
          rota: 'teleatendimentos-compartilhado',
          pagina: 'Teleatendimento Compartilhado',
          icone: faDisplayMedical,
          display: this.helper.toBool(permissoes.IndTeleatendimentoCompartilhado),
        },
        {
          rota: 'agendamentos',
          pagina: 'Agendamentos',
          icone: faCalendarDay,
          display: this.helper.toBool(permissoes.IndAgendamentos),
        },
        {
          rota: 'avisos',
          pagina: 'Avisos',
          icone: faBellPlus,
          display: this.helper.toBool(permissoes.IndAvisos),
        },
        {
          rota: 'minha-saude',
          pagina: 'Minha Saúde',
          icone: faHeartPulse,
          display: this.helper.toBool(permissoes.IndMinhaSaude),
        },
        {
          rota: 'vacinas',
          pagina: 'Vacinas',
          icone: faSyringe,
          display: this.helper.toBool(permissoes.IndVacinas),
        },
        {
          rota: 'documentos',
          pagina: 'Documentos',
          icone: faFile,
          display: this.helper.toBool(permissoes.IndDocAssinado),
        },
        {
          rota: 'noticias',
          pagina: 'Notícias',
          icone: faNewspaper,
          display: this.helper.toBool(permissoes.IndNoticias),
        },
        {
          rota: 'upas',
          pagina: 'Upas',
          icone: faHospital,
          display: this.helper.toBool(permissoes.IndUsoUpa),
        },
        {
          rota: 'unidades-saude-basica',
          pagina: 'Unidades de Saúde',
          icone: faHospital,
          display: this.helper.toBool(permissoes.IndUnidadesBasicas),
        },
        {
          rota: 'servicos',
          pagina: 'Serviços',
          icone: faSquareInfo,
          display: this.helper.toBool(permissoes.IndServUteis),
        },
        {
          rota: 'ouvidoria',
          pagina: 'Ouvidoria',
          icone: faHeadset,
          display: this.helper.toBool(permissoes.IndOuvidoria),
        },
        {
          rota: 'medicamentos',
          pagina: 'Medicamentos',
          icone: faCapsules,
          display: this.helper.toBool(permissoes.IndMedicamentos),
        },
        {
          rota: 'perguntas-frequentes',
          pagina: 'Perguntas Frequentes',
          icone: faMessagesQuestion,
          display: this.helper.toBool(permissoes.IndFaq),
        },
        {
          rota: 'cns',
          pagina: 'CNS',
          icone: faAddressCard,
          display: this.helper.toBool(permissoes.IndCns),
        },
        {
          rota: 'cirurgias',
          pagina: 'Cirurgias',
          icone: faMonitorWaveform,
          display: this.helper.toBool(permissoes.IndCirurgias),
        },
        // {
        //   rota:'',
        //   pagina: 'Alerta Vacina COVID-19',
        //   icone: faHospital,
        //   display: true,
        // }, // Gerso pediu para remover
      ];
      context.menuHref = [
        {
          link: 'https://conectesus-paciente.saude.gov.br/',
          pagina: 'Conecte SUS',
          icone: 'assets/images/conecte_sus_icone.svg',
          display: this.helper.toBool(permissoes.IndConecteSus),
        },
        {
          link: 'https://onelaudos.mobilemed.com.br/entrega/',
          pagina: 'Núcleo de Imagem',
          icone: 'assets/images/nucleo_imagem_icone.svg',
          display: this.helper.toBool(permissoes.IndNucleoImagem),
        },
        {
          link: 'https://acompanhante.ijf.fortaleza.ce.gov.br/',
          pagina: 'IJF',
          icone: 'assets/images/ijf_icone.svg',
          display: this.helper.toBool(permissoes.IndIjf2),
        },
        {
          link: 'https://vacineja.sms.fortaleza.ce.gov.br/',
          pagina: 'Vacine Já',
          icone: 'assets/images/vacine_ja_icone.svg',
          display: this.helper.toBool(permissoes.IndVacinaCovid),
        },
        {
          link: 'https://atendeemcasa.sms.fortaleza.ce.gov.br/login',
          pagina: 'COVID-19',
          icone: 'assets/images/virus.svg',
          display: this.helper.toBool(permissoes.IndCovid),
        }, // url retornando 502
        {
          link: 'https://assistentesara.guarapuava.pr.gov.br/',
          pagina: 'Fale com a Sara',
          icone: 'assets/images/fale_com_sara_icone.svg',
          display: this.helper.toBool(permissoes.IndFaleComSara),
        },
      ];
    });
  }

  ngOnDestroy(): void {
    this.permissoesSubscription.unsubscribe();
  }
}
