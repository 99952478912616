import { environment } from "src/environments/environment";

export function getBaseLocation() {
  if (location.hostname === 'localhost') {
    return '/';
  }
  var req = new XMLHttpRequest();
  req.open('GET', location.href, false);
  req.send(null);
  environment.xCustomerKey = req.getResponseHeader('x-customer-key') || 'Error';
  if (req.getResponseHeader('x-uses-root-domain')) {
    return '/';
  }

  if (req.getResponseHeader('x-uses-root-domain-1')) {
    let paths = location.pathname.split('/').splice(1, 1);
    return `/${paths.join('/')}`;
  }

  let paths = location.pathname.split('/').splice(1, 2);
  return `/${paths.join('/')}`;
}
