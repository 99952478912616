import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnDestroy {
  private loadingServiceSubscription: Subscription;
  public isLoading: boolean = false;

  constructor(private loadingService: LoadingService) {
    this.loadingServiceSubscription = this.loadingService.IsLoading().subscribe(value => {
      this.isLoading = value;
    })
  }

  ngOnDestroy(): void {
    if (this.loadingServiceSubscription) {
      this.loadingServiceSubscription.unsubscribe();
    }
  }

}
