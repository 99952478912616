import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { SettingsService } from '../../../settings/settings.service';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private settingsService: SettingsService,
    private authService: AuthService
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.decryptData(this.settingsService.get(environment.production ? 'key3' : 'authorizationToken'));
    const authToken = token ? `Bearer ${token}` : undefined;
    return of(authToken)
      .pipe(
        switchMap(token => {
          let headers = request.headers
            .set('Content-Type', 'application/json');
          if (token) {
            if (request.url.startsWith(environment.baseServiceUrl)) {
              headers = headers.append('Authorization', token);
            }
          }
          const requestClone = request.clone({ headers });
          return next.handle(requestClone);
        })
      );
  }
}
