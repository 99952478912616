import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Alert } from "./alert.model";

@Injectable({
  providedIn: 'root'
})
export class MessageService implements OnInit, OnDestroy {
  private alert = new Subject<Alert>();
  private alerts: Alert[] = [];

  constructor(
  ) {
    this.alert.subscribe(alert => {
      this.alerts.push(alert);
      setTimeout(() => {
        this.dismiss(alert);
      }, 4000);
    });
  }
  ngOnDestroy(): void {
    this.alert.unsubscribe();
  }

  ngOnInit(): void {
  }

  public showError(defaultMessage: string, error: any = null) {
    let message;
    if (!error || !error.error?.mensagemErro) {
      message = defaultMessage;
    } else {
      message = error.error.mensagemErro;
    }
    this.alert.next({ message, type: 'danger', id: this.getUuid() });
  }

  public showSuccess(message: string) {
    this.alert.next({ message, type: 'success', id: this.getUuid() });
  }

  public showInfo(message: string) {
    this.alert.next({ message, type: 'info', id: this.getUuid() });
  }

  public showWarning(message: string) {
    this.alert.next({ message, type: 'warning', id: this.getUuid() });
  }

  public dismiss(alert: Alert) {
    for (var i = this.alerts.length - 1; i >= 0; i--) {
      if (this.alerts[i].id == alert.id) {
        this.alerts.splice(i, 1);
      }
    }
  }

  public getAlerts(): Alert[] {
    return this.alerts;
  }

  private getUuid(): number {
    if (!this.alerts || this.alerts.length == 0) {
      return 0;
    }

    return this.alerts[this.alerts?.length - 1].id + 1;
  }
}
