import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      const isLoggedIn = from(this.authService.checkLoggedIn())
      .pipe(
        tap(result => {
          if (!result) {
            this.authService.logout();
            this.router.navigate(['/login']);
          }
          return false;
        }));
    return isLoggedIn;

  }
}
