import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';


@Injectable({
  providedIn: 'root'
})
export class HubTeleatendimentoService {
  hubConnection: signalR.HubConnection | undefined;

  constructor( ) { }

  public async clearSessionHubTeleatendimento () {
    if (this.hubConnection?.state == signalR.HubConnectionState.Connected || this.hubConnection?.state == signalR.HubConnectionState.Connecting) {
      this.hubConnection?.stop();
      this.hubConnection?.off("ReceberNotificacaoSimples");
    }
  }
}
