import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of } from "rxjs";
import { ClienteService } from "src/app/seguranca/cliente/cliente.service";
import * as PermissaoActions from './permissao.actions';

@Injectable()
export class PermissaoEffects {

  constructor(private actions$: Actions, private clienteService: ClienteService) { }

  carregarPermissoesCliente$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(PermissaoActions.carregarPermissoesCliente),
        exhaustMap(() => this.clienteService.getConfiguracaoCliente()
          .pipe(
            map(resultado => PermissaoActions.sucessoCarregarPermissoesCliente({ permissoes: resultado.Retorno })),
            catchError(error => of(PermissaoActions.erroCarregarPermissoesCliente({ error })))
          ))
      );
  });
}
