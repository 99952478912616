export const environment = {
  production: false,
  baseServiceUrl: 'https://dev-api.fastmedic.com.br/cidadao/',
  baseAPIGestaoUrl: 'https://dev-api.fastmedic.com.br/multicliente/',
  urlTeleAtendimento: 'https://teleatendimento.fastmedic.com.br:8080/sala/',
  urlHubTeleAtendimento: 'https://homolog.fastmedic.com.br/hubs/NotificacaoHub/',
  urlTokenHubTeleAtendimento: 'https://homolog.fastmedic.com.br/apisaudeprincipal/',
  buildNumber: '1.0.0.9858_20240912',
  xCustomerKey: '',
};

