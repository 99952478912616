import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutPadraoComponent } from './layout/layout-padrao/layout-padrao.component';
import { AuthenticationGuard } from './seguranca/guard/authentication.guard';
import { DependenteGuard } from './seguranca/guard/dependente.guard';
import { PermissoesClienteGuard } from './seguranca/guard/permissoes-cliente.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./seguranca/seguranca.module').then(m => m.SegurancaModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule)
  },
  {
    path: 'meu-perfil',
    loadChildren: () => import('./meu-perfil/meu-perfil.module').then(m => m.MeuPerfilModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: 'dependentes',
    loadChildren: () => import('./dependentes/dependentes.module').then(m => m.DependentesModule),
    canLoad: [AuthenticationGuard],
    canActivate: [DependenteGuard]
  },
  {
    path: 'alterar-senha',
    loadChildren: () => import('./alterar-senha/alterar-senha.module').then(m => m.AlterarSenhaModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: 'termos-de-uso',
    loadChildren: () => import('./termos-de-uso/termos-de-uso.module').then(m => m.TermosDeUsoModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: 'sobre',
    loadChildren: () => import('./sobre/sobre.module').then(m => m.SobreModule),
    canLoad: [AuthenticationGuard]
  },
  {
    path: '', component: LayoutPadraoComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canLoad: [AuthenticationGuard]
      },
      {
        path: 'noticias',
        loadChildren: () => import('./noticias/noticias.module').then(m => m.NoticiasModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard],
      },
      {
        path: 'minha-saude',
        loadChildren: () => import('./minha-saude/minha-saude.module').then(m => m.MinhaSaudeModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'upas',
        loadChildren: () => import('./upas/upas.module').then(m => m.UpasModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'unidades-saude-basica',
        loadChildren: () => import('./unidades-saude-basica/unidades-saude-basica.module').then(m => m.UnidadesSaudeBasicaModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'agendamentos',
        loadChildren: () => import('./agendamentos/agendamentos.module').then(m => m.AgendamentosModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'avisos',
        loadChildren: () => import('./avisos/avisos.module').then(m => m.AvisosModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'vacinas',
        loadChildren: () => import('./vacinas/vacinas.module').then(m => m.VacinasModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'teleatendimentos',
        loadChildren: () => import('./teleatendimentos/teleatendimentos.module').then(m => m.TeleatendimentosModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'teleatendimentos-compartilhado',
        loadChildren: () => import('./teleatendimentos-compartilhado/teleatendimentos-compartilhado.module').then(m => m.TeleatendimentosCompartilhadoModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'documentos',
        loadChildren: () => import('./documentos/documentos.module').then(m => m.DocumentosModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'servicos',
        loadChildren: () => import('./servicos/servicos.module').then(m => m.ServicosModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'ouvidoria',
        loadChildren: () => import('./ouvidoria/ouvidoria.module').then(m => m.OuvidoriaModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'medicamentos',
        loadChildren: () => import('./medicamentos/medicamentos.module').then(m => m.MedicamentosModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'perguntas-frequentes',
        loadChildren: () => import('./perguntas-frequentes/perguntas-frequentes.module').then(m => m.PerguntasFrequentesModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'cns',
        loadChildren: () => import('./cns/cns.module').then(m => m.CnsModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      },
      {
        path: 'cirurgias',
        loadChildren: () => import('./cirurgias/cirurgias.module').then(m => m.CirurgiasModule),
        canLoad: [AuthenticationGuard],
        canActivate: [PermissoesClienteGuard]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
