import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, fromEvent, Observable, Subscription, throwError } from 'rxjs';
import { AuthService } from 'src/app/seguranca/auth/auth.service';
import { faBars, faArrowRightFromBracket, faUser, faInfoSquare, faFileLines, faLockKeyhole, faTrashCan, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/shared/message.service';
import { Helper } from 'src/app/shared/helper';
import { LoadingService } from 'src/app/shared/loading.service';
import { UsuarioService } from 'src/app/usuarios/usuario.service';
import { UsuarioInformacoes } from 'src/app/usuarios/usuario-informacoes.model';
import { UsuarioExcluir } from 'src/app/usuarios/usuario-excluir.model';
import { getPermissoesCliente, PermissaoState } from 'src/app/seguranca/cliente/state/permissao.reducer';
import { Store } from '@ngrx/store';
import { ListarDependente } from 'src/app/dependentes/listar-dependente.model';
import { Dependente } from 'src/app/dependentes/dependente.model';
import { SettingsService } from 'src/settings/settings.service';
import { environment } from 'src/environments/environment';
import { DependenteService } from 'src/app/dependentes/dependentes.service';
import { faGear } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-menu-superior',
  templateUrl: './menu-superior.component.html',
  styleUrls: ['./menu-superior.component.scss']
})

export class MenuSuperiorComponent implements OnDestroy {
  @Output() clique = new EventEmitter<boolean>();
  faBars = faBars;
  faGear = faGear;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faUser = faUser;
  faUsers = faUsers;
  faInfoSquare = faInfoSquare;
  faFileLines = faFileLines;
  faLockKeyhole = faLockKeyhole;
  faTrashCan = faTrashCan;
  larguraTela =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  resizeObservable: Observable<Event> = fromEvent(window, 'resize');
  verificarResize: any;
  opcoesAbertas: boolean = false;
  form: FormGroup;
  request: UsuarioExcluir = {
    codigoUsuarioMobile: 0,
    senha: '',
    confirmarSenha: ''
  };
  indDependentes = false;
  mostraDependentes: boolean = false;
  usuarioSelecionado: string = '';
  usuario: UsuarioInformacoes = {
    codigoUsuarioMobile: 0,
    codigoUsuarioSaude: 0,
    descricaoEmail: '',
    indicadorSenhaTemporaria: 0,
    nomeMae: '',
    nomeUsuarioMobile: '',
    numeroCartaoNacionalTabelaUsuarioCartao: '',
    numeroCartaoNacionalTabelaUsuarioMobile: '',
    numeroCpf: '',
    numeroDispositivo: '',
    telefoneUsuarioMobile: ''
  };
  cpfResponsavel: string = '';
  dependentes: Dependente[] = [];
  permissoesSubscription: Subscription;
  codigoUsuarioSaude: number = 0;
  dispositivoMobile = this.larguraTela < 576;

  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private modalService: NgbModal,
    private helper: Helper,
    private loadingService: LoadingService,
    private usuarioService: UsuarioService,
    private permissaoStore: Store<PermissaoState>,
    private settingsService: SettingsService,
    private dependenteService: DependenteService,
    ) {
    this.form = this.formBuilder.group({
      senha: [
        '', [Validators.maxLength(15),
        Validators.minLength(4),
        Validators.required]
      ],
      confirmarSenha: [
        '', [Validators.required,
        this.helper.comparaSenhaValidator('senha')]
      ]
    });

    this.codigoUsuarioSaude = this.authService.decryptData(this.settingsService.get(environment.production ? 'key7' : 'codigoUsuarioSaudeUsuarioSelecionado'));
    this.cpfResponsavel = this.authService.decryptData(this.settingsService.get(environment.production ? 'key4' : 'cpfUsuario'));
    this.usuarioSelecionado = this.authService.decryptData(this.settingsService.get(environment.production ? 'key6' : 'usuarioSelecionado'));

    this.permissoesSubscription = this.permissaoStore.select(getPermissoesCliente).subscribe(permissoes => {
      this.indDependentes = this.helper.toBool(permissoes.IndDependentes);
      this.buscarUsuario();
    });

    this.verificarResize = this.resizeObservable.subscribe((evt) => {
      this.larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.dispositivoMobile = this.larguraTela < 576;
    });
  }

  cliqueIconeMenu(event: MouseEvent) {
    let houveClique = Boolean(event)
    this.clique.emit(houveClique);
  }

  buscarUsuario() {
    this.usuarioService.buscar()
      .pipe(
        catchError(error => {
          this.messageService.showError(`Erro ao buscar o usuário! Tente novamente mais tarde!`, error);
          this.usuario = this.usuario;
          return throwError(() => new Error(error));
        }))
      .subscribe(data => {
        if (data == null) {
          this.messageService.showError(`Erro ao buscar o usuário! Tente novamente mais tarde!`);
          this.usuario = this.usuario;
        }
        else {
          this.usuario = data as UsuarioInformacoes;
          this.buscarDependentes(this.usuario.codigoUsuarioMobile);
        }
      });
  }

  buscarDependentes(codigo: number) {
    let request: ListarDependente = {
      codigoUsuarioMobile: codigo,
      cpfResponsavel: this.cpfResponsavel,
      indPermissaoDependente: 0
    }
    this.dependenteService.listarDependentes(request)
      .pipe(
        catchError(error => {
          this.messageService.showError(`Erro ao buscar o(s) dependente(s)! Tente novamente mais tarde!`, error);
          this.dependentes = [];
          this.mostraDependentes = false;
          return throwError(() => new Error(error));
        }))
      .subscribe(data => {
        if (data == null) {
          this.messageService.showError(`Erro ao buscar o(s) dependente(s)! Tente novamente mais tarde!`);
          this.dependentes = [];
          this.mostraDependentes = false;
        }
        else {
          this.dependentes = data as Dependente[];
          (this.dependentes.length > 0) ? this.mostraDependentes = true : this.mostraDependentes = false;
        }
      });
  }

  mudarUsuarioSelecionado(nomUsuarioSelecionado: string, codigoUsuarioSaudeUsuarioSelecionado: number, cpfUsuarioSelecionado: string, reload: boolean = true) {
    this.settingsService.unset(environment.production ? 'key6' : 'usuarioSelecionado');
    this.settingsService.set(environment.production ? 'key6' : 'usuarioSelecionado', this.authService.encryptData(nomUsuarioSelecionado.toUpperCase()));
    this.usuarioSelecionado = this.authService.decryptData(this.settingsService.get(environment.production ? 'key6' : 'usuarioSelecionado'));
    this.settingsService.unset(environment.production ? 'key7' : 'codigoUsuarioSaudeUsuarioSelecionado');
    this.settingsService.set(environment.production ? 'key7' : 'codigoUsuarioSaudeUsuarioSelecionado', this.authService.encryptData(codigoUsuarioSaudeUsuarioSelecionado));
    this.settingsService.unset(environment.production ? 'key8' : 'cpfUsuarioSelecionado');
    this.settingsService.set(environment.production ? 'key8' : 'cpfUsuarioSelecionado', this.authService.encryptData(cpfUsuarioSelecionado));
    this.codigoUsuarioSaude = this.authService.decryptData(this.settingsService.get(environment.production ? 'key7' : 'codigoUsuarioSaudeUsuarioSelecionado'));
    if(reload) {
      location.reload();
    }
    this.fecharOpcoesDoUsuario();
  }

  exibirOpcoesDoUsuario() {
    this.opcoesAbertas = !this.opcoesAbertas;
  }

  fecharOpcoesDoUsuario() {
    this.opcoesAbertas = false;
  }

  deslogar() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  abrirModal(modal: any) {
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' });
  }

  verificarFormulario() {
    if (this.form.invalid)
      return this.messageService.showError(`Preencha os campos corretamente para continuar!`);

    this.excluir()
  }

  excluir() {
    this.modalService.dismissAll();
    this.request = this.form.value;
    this.request.codigoUsuarioMobile = this.usuario.codigoUsuarioMobile;

    try {
      this.loadingService.startLoading();
      this.usuarioService.excluir(this.request)
        .pipe(
          catchError(error => {
            this.loadingService.stopLoading();
            this.messageService.showError(`Erro ao excluir a conta! Tente novamente mais tarde!`, error);
            return throwError(() => new Error(error));
          }))
        .subscribe(data => {
          this.loadingService.stopLoading();
          if (!data) {
            this.messageService.showError(`Erro ao excluir a conta! Tente novamente mais tarde!`);
            return;
          } else {
            this.authService.logout();
            this.router.navigate(['/login']);
            this.messageService.showSuccess(`Conta excluída com sucesso!`);
            return;
          }
        })
    }
    catch {
      this.loadingService.stopLoading(true);
      return this.messageService.showError(`Erro ao excluir a conta! Tente novamente mais tarde!`);
    }
  }

  ngOnDestroy() {
    this.verificarResize.unsubscribe();
    this.permissoesSubscription.unsubscribe();
  }
}
