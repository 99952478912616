
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Resultado } from "./cliente-resultado.model";

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  constructor(private http: HttpClient) {}

  getConfiguracaoCliente(): Observable<Resultado> {
    const url = `${environment.baseAPIGestaoUrl}Cliente/BuscaConfiguracaoClientePorNome?nomCliente=${environment.xCustomerKey}`;
    return this.http.get<Resultado>(url);
  }

  getDescricaoParametro(codigoCliente: number, codigoParametro: string): Observable<Resultado> {
    const url = `${environment.baseAPIGestaoUrl}Cliente/BuscaDescricaoParametro?codCliente=${codigoCliente}&codParametro=${codigoParametro}`;
    return this.http.get<Resultado>(url);
  }

  getParametros(codigoCliente: number, codigoParametros: string[]): Observable<Resultado> {
    var url = `${environment.baseAPIGestaoUrl}Cliente/BuscaParametros?codCliente=${codigoCliente}`;
    codigoParametros.forEach(codigoParametro => { url += `&codParametros=${codigoParametro}` });
    return this.http.get<Resultado>(url);
  }
}
