import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

enum StorageType {
  Service,
  LocalStorage
}

@Injectable()
export class SettingsService {
  private storageType: StorageType = StorageType.LocalStorage;

  get(name: string | number) {
    if (this.storageType === StorageType.LocalStorage) {
      return localStorage[`cidadao.${environment.xCustomerKey.toLowerCase()}.${name}`];
    }
  }

  set(name: string | number, value: any) {
    if (this.storageType === StorageType.LocalStorage) {
      localStorage[`cidadao.${environment.xCustomerKey.toLowerCase()}.${name}`] = value;
    }
  }

  unset(name: string) {
    if (this.storageType === StorageType.LocalStorage) {
      localStorage.removeItem(`cidadao.${environment.xCustomerKey.toLowerCase()}.${name}`);
    }
  }
}
