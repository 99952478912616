import { createAction, props } from "@ngrx/store";
import { ClienteUrlApi } from "../cliente-url-api.model";

export const carregarPermissoesCliente = createAction(
  '[Permissao] Carregar permissoes cliente'
);

export const sucessoCarregarPermissoesCliente = createAction(
  '[Permissao] Sucesso ao carregar permissoes cliente',
  props<{ permissoes: ClienteUrlApi }>()
);

export const erroCarregarPermissoesCliente = createAction(
  '[Permissao] Erro ao carregar permissoes cliente',
  props<{ error: string }>()
);
