import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IMaskModule } from 'angular-imask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BarraPesquisaComponent } from './barra-pesquisa/barra-pesquisa/barra-pesquisa.component';
import { TelaSemConteudoComponent } from './tela-sem-conteudo/tela-sem-conteudo.component';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [
    BarraPesquisaComponent,
    TelaSemConteudoComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    IMaskModule,
    NgbModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    IMaskModule,
    NgbModule,
    BarraPesquisaComponent,
    TelaSemConteudoComponent,
    LoadingComponent,
  ],
})
export class SharedModule {}
