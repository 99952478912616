import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './seguranca/http-interceptor/auth-interceptor';
import { AuthService } from './seguranca/auth/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { PermissaoEffects } from './seguranca/cliente/state/permissao.effects';
import { permissaoReducer } from './seguranca/cliente/state/permissao.reducer';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { getBaseLocation } from './shared/app-base-ref-helper';
import { Helper } from './shared/helper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutPadraoComponent } from './layout/layout-padrao/layout-padrao.component';
import { MenuLateralComponent } from './layout/menu/menu-lateral/menu-lateral.component';
import { MenuSuperiorComponent } from './layout/menu/menu-superior/menu-superior.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { SettingsService } from 'src/settings/settings.service';
import { SharedModule } from './shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { OAuthModule } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [AppComponent, LayoutPadraoComponent, MenuSuperiorComponent, MenuLateralComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    StoreModule.forRoot({permissao: permissaoReducer}),
    EffectsModule.forRoot([PermissaoEffects]),
    StoreDevtoolsModule.instrument({ name: 'PortalCidadao', maxAge: 25, logOnly: environment.production }),
    OAuthModule.forRoot(),
  ],
  providers: [
    Helper,
    // { provide: LOCALE_ID, useValue: 'pt-BR' },
    // { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: GeneralErrorHandler },
    { provide: APP_BASE_HREF, useFactory: getBaseLocation },
    SettingsService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}