<div class="menu-lateral mh-auto min-vh-sm-100 h-100 w-100">
  <div *ngFor="let item of menuRouterLink">
    <a *ngIf="item.display" [routerLink]="item.rota" class="menu-item d-flex flex-sm-column align-items-center justify-content-start justify-content-sm-center p-2">
      <fa-icon [icon]="item.icone" class="m-0 text-white"></fa-icon>
      <p class="text-white text-uppercase text-start text-sm-center mx-2 mx-sm-0 mb-0 mt-0 mt-sm-1">{{ item.pagina }}</p>
    </a>
  </div>
  <div *ngFor="let item of menuHref">
    <a *ngIf="item.display" href="{{ item.link }}" target="_blank" class="menu-item d-flex flex-sm-column align-items-center justify-content-start justify-content-sm-center p-2">
      <img src="{{ item.icone }}" class="m-0 text-white icone-href" alt="{{ item.pagina }}">
      <p class="text-white text-uppercase text-start text-sm-center mx-2 mx-sm-0 mb-0 mt-0 mt-sm-1">{{ item.pagina }}</p>
    </a>
  </div>
</div>
