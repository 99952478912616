import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { shareReplay, switchMap } from 'rxjs';
import { AuthService } from '../seguranca/auth/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { UsuarioCadastro } from './usuario-novo/usuario-cadastro.model';
import { RecuperarSenha } from '../seguranca/recuperar-senha/recuperar-senha.model';
import { UsuarioAtualizar } from './usuario-atualizar.model';
import { UsuarioAlterarSenha } from './usuario-alterar-senha.model';
import { UsuarioExcluir } from './usuario-excluir.model';
import { UsuarioTokenHubTeleatendimentoRequest } from './usuario-token-hub-teleatendimento-request.model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private helper: Helper,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  buscar() {
    return this.http.get(`${environment.baseServiceUrl}usuarios`)
      .pipe(
        switchMap(async response => {
          return response;
        }),
        shareReplay()
      );
  }

  cadastrar(request: UsuarioCadastro) {
    const httpOptions: { headers: any; observe: any; } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Customer-Key': environment.xCustomerKey
      }),
      observe: 'response'
    };

    return this.http.post(`${environment.baseServiceUrl}usuarios`, request, httpOptions)
      .pipe(
        switchMap(async response => {
          return response;
        }),
        shareReplay()
      );
  }

  recuperarSenha(request: RecuperarSenha) {
    const httpOptions: { headers: any; observe: any; } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Customer-Key': environment.xCustomerKey
      }),
      observe: 'response'
    };

    return this.http.post(`${environment.baseServiceUrl}usuarios/recuperar-senha`, request, httpOptions)
      .pipe(
        switchMap(async response => {
          return response;
        }),
        shareReplay()
      );
  }

  atualizar(request: UsuarioAtualizar) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/atualizar`, request)
  }

  alterarSenha(request: UsuarioAlterarSenha) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/alterar-senha`, request)
  }

  excluir(request: UsuarioExcluir) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/excluir`, request)
  }

  verificarCpfSaude(codigoUsuarioSaude: number, indPermissaoDependente: number, cpfUsuario: string) {
    return this.http.get(`${environment.baseServiceUrl}usuarios/${codigoUsuarioSaude}/${indPermissaoDependente}/possui-cpf-saude/${cpfUsuario}`);
  }

  //// versao compativel com API
  // gerarTokenHubTeleatendimento (codigoUsuarioSaude: number, codigoCliente: number, nomeCliente: string) {
  //   return this.http.get(`${environment.baseServiceUrl}usuarios/${codigoUsuarioSaude}/codigo-cliente/${codigoCliente}/nome-cliente/${nomeCliente}/token-hub-teleatendimento`);
  // }
  gerarTokenHubTeleatendimento (request: UsuarioTokenHubTeleatendimentoRequest) {
    if (request.nomeCliente == "Homologacao")
      request.nomeCliente = "homolog"
    return this.http.post(`${environment.urlTokenHubTeleAtendimento}api/Chats/clientes/${request.nomeCliente}/usuarios/${request.codigoUsuarioSaude}/logadoportal/gerartoken?duracao=1440`, request)
  }
}
